import React from "react"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBath,
  faBed,
  faCar,
  faRocket,
} from "@fortawesome/free-solid-svg-icons"
import { postTypesSlug } from "../../content/Global"
import { Link } from "gatsby"
import Button from "../atoms/Button"

const PropertyCard = ({ data, componentEC }) => {
  const imageURL = ImageUrlRenderer({ img: data.featuredImage.path })
  return (
    <div className={`shadow border rounded-[12px] bg-white ${componentEC}`}>
      <Link to={postTypesSlug.propertiesSlug + data.slug + "/"}>
        <div
          className="h-[220px] bg-center bg-cover flex items-end justify-end pt-10 rounded-tl-[12px] rounded-tr-[12px]"
          style={{ backgroundImage: `url(${imageURL})` }}
        >
          <div className="bg-[#ffffffc2] rounded-tl-[8px] flex gap-[10px] text-[12px] font-[700] font-ubuntu py-2 px-2">
            <span className="text-secondary font-ubuntu">
              {data.propertyFor}
            </span>
            <div className="border border-[#848484]"></div>
            <span className="text-secondary font-ubuntu">{data.category}</span>
            <div className="border border-[#848484]"></div>
            <span
              className={
                `text-secondary font-ubuntu ` +
                (data.propertyStatus === "Expired" ? "!text-[#848484]" : "")
              }
            >
              {data.propertyStatus}
            </span>
          </div>
        </div>
      </Link>
      <div className="">
        <div className="px-3 py-4">
          <Link to={postTypesSlug.propertiesSlug + data.slug + "/"}>
            <h4
              className={`text-[18px] leading-[1.4em] font-[600] text-[#000000] md:min-h-[50px] mb-1 ${data.titleEC}`}
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          </Link>
          <p
            className="text-[12px] leading-[1.4em] text-[#081615]"
            dangerouslySetInnerHTML={{ __html: data.address }}
          />
        </div>
        <div className="flex gap-[15px] px-3 text-[#727272] text-[12px]">
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faBed} className="text-[#1C9F85]" />
            <span>{data.beds}</span>
          </div>
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faBath} className="text-[#1C9F85]" />
            <span>{data.baths}</span>
          </div>
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faCar} className="text-[#1C9F85]" />
            <span>{data.cars}</span>
          </div>
          <span>|</span>
          <span className="font-[600]">{data.propertyType}</span>
        </div>
        <div className="px-3 mt-3">
          <div className="flex gap-2 items-center text-[12px] text-[#1C9F85]">
            <FontAwesomeIcon icon={faRocket} className="" />
            <span>{data.vacancies}</span>
          </div>
        </div>
        <p
          className="text-[12px] px-3 mt-3 leading-[1.4em] text-[#25282C]"
          dangerouslySetInnerHTML={{
            __html: "Available date: " + data.availableDate,
          }}
        />
      </div>

      <div className="mt-3 px-3 py-3 border-t border-[#e6e6e6]">
        <div className={`flex flex-wrap justify-between gap-[10px]`}>
          <Button
            to={postTypesSlug.propertiesSlug + data.slug + "/"}
            href={"#"}
            text={"View Property"}
            ec={"primary-transparent-btn !text-[#25282C] !py-[5px]"}
          />
          <Button
            to={postTypesSlug.propertiesSlug + "contact-us/"}
            href={"#"}
            text={"Book Property"}
            ec={"secondary-btn !py-[5px]"}
          />
        </div>
      </div>
    </div>
  )
}

export default PropertyCard
