import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"

const Faqs = ({ faqs, questionEC, answerEC, iconEC, searchQuery }) => {
  const [openIndex, setOpenIndex] = useState(null) // Track the currently open FAQ index

  const filteredFaqs = searchQuery
    ? faqs.filter(
        faq =>
          faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : faqs

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index) // Close if already open, otherwise open the clicked one
  }

  return (
    <div className="mx-auto divide-y divide-gray-900/10 faqs-section-cursor">
      <dl className="flex flex-col gap-[20px]">
        {filteredFaqs?.length > 0 ? (
          filteredFaqs?.map((faq, index) => (
            <div
              key={"faqs_" + index}
              className="rounded-[8px] !mt-0"
              style={{ boxShadow: `0px 0px 10px #d2caca76` }}
            >
              <dt>
                <button
                  className="flex w-full items-start justify-between text-left pt-4 pb-2 px-6 cursor-pointer"
                  onClick={() => handleToggle(index)}
                >
                  <span
                    className={`text-[18px] font-[700] leading-[1.5em] ${
                      openIndex === index ? "text-secondary" : "text-[#10242C]"
                    } ${questionEC}`}
                    dangerouslySetInnerHTML={{ __html: faq.question }}
                  />
                  <span
                    className={
                      "ml-6 text-[20px] min-w-[35px] min-h-[35px] text-secondary text-center flex items-center justify-center " +
                      iconEC
                    }
                  >
                    {openIndex === index ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </button>
              </dt>
              {openIndex === index && (
                <dd className="pb-5 px-6">
                  <p
                    className={
                      "text-[16px] leading-[1.3em] text-[#10242C] " + answerEC
                    }
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                </dd>
              )}
            </div>
          ))
        ) : (
          <p>No FAQs match your search query.</p>
        )}
      </dl>
    </div>
  )
}

export default Faqs
